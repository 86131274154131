import React, { useEffect, useState } from "react"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
// import { MDXRenderer } from "gatsby-plugin-mdx"
// import { MDXProvider } from "@mdx-js/react"
import ReactMarkdown from "react-markdown"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import {
  Toolbar,
  Breadcrumbs,
  Link,
  Typography,
  Box,
  Container,
} from "@material-ui/core"
import FacebookIcon from "@material-ui/icons/Facebook"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import MailIcon from "@material-ui/icons/Mail"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { vscDarkPlus as syntaxTheme } from "react-syntax-highlighter/dist/esm/styles/prism"
import rehypeRaw from "rehype-raw"

import Layout from "../components/layout"


// Sample Markdown with Javascript code
const markdown = `Here is some JavaScript code:

~~~shell
$ whoami
~~~
`

export const query = graphql`
  query ArticleQuery($id: Int!) {
    strapiArticle(strapiId: { eq: $id }) {
      title
      description
      content
      keywordsMeta
      featured_image {
        publicURL
      }
      tags {
        name
      }
      created_at
    }
  }
`

const StyledToolbar = withStyles({
  root: {
    background: "#031242",
  },
})(Toolbar)

function Blog({ data }) {
  const article = data.strapiArticle
  const [shareLink, setShareLink] = useState()

  useEffect(() => {
    if (!(typeof window === "undefined")) {
      setShareLink(window.location.href)
    }
  }, [])

  const fblink = url => {
    const options = "toolbar=0,status=0,resizable=1,width=626,height=436"
    window.open(url, "sharer", options)
  }

  const linkedinlink = url => {
    const options = "toolbar=0,status=0,resizable=1,width=626,height=436"
    window.open(url, "sharer", options)
  }

  return (
    <Layout
      siteTitle={article.title}
      description={article.description}
      keywords={article.keywordsMeta}
      ogImage={article.featured_image?.publicURL}
    >
      <StyledToolbar />
      <Box position="fixed" top="40%" left="0" bottom="60%">
        <Box borderRadius="0px 5px 5px 0px" overflow="hidden">
          <Link
            onClick={() => {
              fblink(
                `http://www.facebook.com/sharer/sharer.php?href=${shareLink}&display=page&ref=plugin&src=share_button`
              )
            }}
          >
            <Box
              bgcolor="#4267B2"
              color="white"
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FacebookIcon />
            </Box>
          </Link>
          <Link
            href={`mailto:?subject=${article.title}&body=${shareLink}`}
            target="_blank"
          >
            <Box
              bgcolor="#DB4437"
              color="white"
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MailIcon />
            </Box>
          </Link>
          <Link
            onClick={() => {
              linkedinlink(
                `https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&source=phaidelta.com`
              )
            }}
          >
            <Box
              bgcolor="#0077B5"
              color="white"
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LinkedInIcon />
            </Box>
          </Link>
          <Link href={`https://wa.me/?text=${shareLink}`} target="_blank">
            <Box
              bgcolor="#25D366"
              color="white"
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <WhatsAppIcon />
            </Box>
          </Link>
        </Box>
      </Box>
      <Container>
        <Box pt={5}>
          <Box display="flex" justifyContent="flex-end">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" component={GatsbyLink} to="/">
                Home
              </Link>
              <Link color="inherit" component={GatsbyLink} to="/blog/">
                Blog
              </Link>
              <Typography color="textPrimary">
                {article.title.substring(0, 20).concat("...")}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box py={5}>
          <Typography variant="h4" gutterBottom color="primary" align="center">
            <b>{article.title}</b>
          </Typography>
          <div>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <AccessTimeIcon fontSize="small" style={{ paddingRight: 5 }} />
              {new Date(article.created_at).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>

            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={article.content}
              transformImageUri={uri =>
                uri.startsWith("http")
                  ? uri
                  : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
              }
              className="dynamicContent"
              escapeHtml={false}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "")
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, "")}
                      style={syntaxTheme}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  )
                },
              }}
            />
          </div>
        </Box>
      </Container>
    </Layout>
  )
}

export default Blog
